import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import Button from 'react-bootstrap/Button';

import styles from './hero.module.scss';

import { useHeroState } from './hero-context';
import LanguageLink from '../language-link/language-link';

const query = graphql`
  {
    allFile(filter: { relativePath: { glob: "header/**" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxHeight: 800, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query);
  const [heroState] = useHeroState();

  if (!heroState) {
    return null;
  }

  const { title, subTitle, buttonText, buttonUrl, imageName } = heroState;

  const { node } = data.allFile.edges.find(
    (edge) => edge.node.name === imageName
  );

  return (
    <div className={styles.root}>
      <div className={styles.backgroundImage}>
        <div className={styles.imageContainer}>
          <div className={styles.image}>
            <Image {...node.childImageSharp} loading="eager" />
          </div>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.content}>
          {title && <h1 className={styles.title}>{title}</h1>}
          {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
          {buttonText && buttonUrl && (
            <Button variant="outline-red" route={buttonUrl} as={LanguageLink}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
