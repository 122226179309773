import React from 'react';
import classnames from 'classnames';

const Hamburger = ({ isActive, ...props }) => {
  const buttonClasses = classnames('hamburger', 'hamburger--squeeze', {
    'is-active': isActive,
  });

  return (
    <button className={buttonClasses} type="button" {...props}>
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

export default Hamburger;
