import React from 'react';
import { CookieConsentsProvider } from '@enzsft/react-cookie-consents';
import { HeroStateProvider } from '../components/hero/hero-context';
import { I18nextProvider } from 'react-i18next';

import i18n from '../i18n';

const RootLayout = ({ children, pageContext }) => {
  i18n.changeLanguage(pageContext.language);

  return (
    <I18nextProvider i18n={i18n}>
      <CookieConsentsProvider cookieName="manument.consent" expiryInDays={365}>
        <HeroStateProvider>{children}</HeroStateProvider>
      </CookieConsentsProvider>
    </I18nextProvider>
  );
};

export default RootLayout;
