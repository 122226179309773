import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.scss';

import instagramIcon from '../../images/027-CAF-Typo_Webseite_SOME_Icons-Insta.svg';
import linkedinIcon from '../../images/027-CAF-Typo_Webseite_SOME_Icons-Linkedin.svg';
import facebookIcon from '../../images/027-CAF-Typo_Webseite_SOME_Icons-fb.svg';
import LanguageLink from '../language-link/language-link';

const Footer = () => {
  const { t } = useTranslation('menu');

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.container}>
          <ul className={styles.menu}>
            <li>
              <LanguageLink route="imprint" activeClassName="active">
                {t('imprint')}
              </LanguageLink>
            </li>
            <li>
              <LanguageLink route="data-protection" activeClassName="active">
                {t('data-protection')}
              </LanguageLink>
            </li>
            <li>
              <LanguageLink route="agb" activeClassName="active">
                {t('agb')}
              </LanguageLink>
            </li>
          </ul>
          <div className={styles.socialLinks}>
            <a
              href="https://www.instagram.com/manumentcoffee/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={instagramIcon} alt="Follow us on Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/manument/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={linkedinIcon} alt="Find us on LinkedIn" />
            </a>
            <a
              href="https://www.facebook.com/manumentcoffee"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={facebookIcon} alt="Follow us on Facebook" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
