import React, { useState, useEffect } from 'react';
import { useMedia, useWindowScroll } from 'react-use';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

import styles from './hero-nav.module.scss';

import logoBlack from '../../images/CAF-Logo-Manument-schwarz_RZ_RGB.svg';
import logoWhite from '../../images/CAF-Logo-Manument-weiss_RZ_RGB.svg';
import LanguageLink from '../language-link/language-link';
import Hamburger from '../hamburger/hamburger';

const HeroNav = ({ currentRoute, ...props }) => {
  const [open, setOpen] = useState(false);
  const isWide = useMedia('(min-width: 800px)');
  const { y } = useWindowScroll();
  const { t, i18n } = useTranslation('menu');

  useEffect(() => {
    setOpen(false);
  }, [isWide]);

  const isStatic = y < 600;
  const logoImage = isStatic && !open ? logoBlack : logoWhite;

  const barClasses = classnames(styles.bar, {
    [styles.barOpen]: open,
    [styles.barStatic]: isStatic && !open,
  });

  return (
    <div className={barClasses}>
      <Container>
        <div className={styles.mobileTitle}>
          <LanguageLink route="index" onClick={() => setOpen(false)}>
            {!open && (
              <img style={{ width: '50%' }} src={logoImage} alt="Manument" />
            )}
          </LanguageLink>
        </div>
        <div
          className={classnames(styles.content, { [styles.contentOpen]: open })}
        >
          <LanguageLink route="index" onClick={() => setOpen(false)}>
            <img
              className={classnames(styles.logo, { [styles.logoOpen]: open })}
              src={logoImage}
              alt="Manument"
            />
          </LanguageLink>
          <div className={styles.menuWrapper}>
            <ul className={styles.mainMenu}>
              <li>
                <LanguageLink
                  route="manument-leva-maschine"
                  activeClassName={styles.linkActive}
                  onClick={() => setOpen(false)}
                >
                  {t('manument-leva-maschine')}
                </LanguageLink>
              </li>
              <li>
                <LanguageLink
                  route="howto"
                  activeClassName={styles.linkActive}
                  onClick={() => setOpen(false)}
                >
                  {t('howto')}
                </LanguageLink>
              </li>
              <li>
                <LanguageLink
                  route="preorder"
                  activeClassName={styles.linkActive}
                  onClick={() => setOpen(false)}
                >
                  {t('preorder')}
                </LanguageLink>
              </li>
              <li>
                <LanguageLink
                  route="about"
                  activeClassName={styles.linkActive}
                  onClick={() => setOpen(false)}
                >
                  {t('about')}
                </LanguageLink>
              </li>
              <li>
                <LanguageLink
                  route="faq"
                  activeClassName={styles.linkActive}
                  onClick={() => setOpen(false)}
                >
                  {t('FAQ')}
                </LanguageLink>
              </li>
              <li>
                <LanguageLink
                  route="press"
                  activeClassName={styles.linkActive}
                  onClick={() => setOpen(false)}
                >
                  {t('press')}
                </LanguageLink>
              </li>
            </ul>
            <ul className={styles.languageMenu}>
              <li>
                <LanguageLink
                  route={currentRoute}
                  language="en"
                  className={classnames(styles.languageToggle, {
                    [styles.languageToggleActive]: i18n.language === 'en',
                  })}
                  onClick={() => setOpen(false)}
                >
                  en
                </LanguageLink>
              </li>
              <li>
                <LanguageLink
                  route={currentRoute}
                  language="de"
                  className={classnames(styles.languageToggle, {
                    [styles.languageToggleActive]: i18n.language === 'de',
                  })}
                  onClick={() => setOpen(false)}
                >
                  de
                </LanguageLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.burger}>
          <Hamburger isActive={open} onClick={() => setOpen(!open)} />
        </div>
      </Container>
    </div>
  );
};

export default HeroNav;
