import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useCookieConsents } from '@enzsft/react-cookie-consents';
import { Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import styles from './cookie-banner.module.scss';

import LanguageLink from '../language-link/language-link';

const CookieBanner = () => {
  // Assume consent is given to exclude the cookie banner from SSR code.
  const [hasConsent, setHasConsent] = useState(true);
  const cookieConsents = useCookieConsents();

  useEffect(() => {
    setHasConsent(cookieConsents.get().length > 0);
  }, [cookieConsents]);

  const rootClasses = classnames(styles.root, {
    [styles.visible]: !hasConsent,
  });

  return (
    <div className={rootClasses}>
      {!hasConsent && (
        <Trans i18nKey="content" ns="cookie">
          <p>
            This website uses cookies to ensure you get the best experience on
            our website.
            <br />
            <LanguageLink route="data-protection">Learn more</LanguageLink>
          </p>
          <Button
            variant="outline-red"
            onClick={() => cookieConsents.add('analytics')}
            block
          >
            Got it!
          </Button>
        </Trans>
      )}
    </div>
  );
};

export default CookieBanner;
