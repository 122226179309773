import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from '../translations/de.yml';
import en from '../translations/en.yml';

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV !== 'production',
  resources: { de, en },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'td'],
  },
});

export default i18n;
