// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-manument-leva-maschine-js": () => import("/opt/build/repo/src/templates/manument-leva-maschine.js" /* webpackChunkName: "component---src-templates-manument-leva-maschine-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-preorder-js": () => import("/opt/build/repo/src/templates/preorder.js" /* webpackChunkName: "component---src-templates-preorder-js" */),
  "component---src-templates-press-js": () => import("/opt/build/repo/src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-imprint-js": () => import("/opt/build/repo/src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-data-protection-js": () => import("/opt/build/repo/src/templates/data-protection.js" /* webpackChunkName: "component---src-templates-data-protection-js" */),
  "component---src-templates-agb-js": () => import("/opt/build/repo/src/templates/agb.js" /* webpackChunkName: "component---src-templates-agb-js" */),
  "component---src-templates-faq-js": () => import("/opt/build/repo/src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-howto-js": () => import("/opt/build/repo/src/templates/howto.js" /* webpackChunkName: "component---src-templates-howto-js" */)
}

