import React, { useContext, useEffect, useState } from 'react';

const HeroContext = React.createContext([]);

export const HeroStateProvider = ({ children }) => {
  const heroState = useState();

  return (
    <HeroContext.Provider value={heroState}>{children}</HeroContext.Provider>
  );
};

export const useHeroState = () => useContext(HeroContext);

export const HeroState = ({
  title,
  subTitle,
  buttonText,
  buttonUrl,
  imageName,
  clear = false,
}) => {
  const [, setHeroState] = useHeroState();

  useEffect(() => {
    setHeroState(
      clear
        ? null
        : {
            title,
            subTitle,
            buttonText,
            buttonUrl,
            imageName,
          }
    );
  }, [title, subTitle, buttonText, buttonUrl, imageName, clear, setHeroState]);

  return null;
};
