import React from 'react';

import '../scss/_base.scss';

import HeroNav from '../components/hero-nav/hero-nav';
import Hero from '../components/hero/hero';
import Footer from '../components/footer/footer';
import CookieBanner from '../components/cookie-banner/cookie-banner';

const Layout = ({ children, pageContext }) => (
  <>
    <HeroNav currentRoute={pageContext.route} />
    <Hero />
    {children}
    <Footer />
    <CookieBanner />
  </>
);

export default Layout;
