import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import routes from '../../../translations/routes';

const LanguageLink = ({ route, language, children, ...props }) => {
  const { i18n } = useTranslation();

  const toLanguage = language || i18n.language;
  const slug = routes[route][toLanguage] ? routes[route][toLanguage] : '';
  const path = toLanguage === 'de' ? slug : `/${toLanguage}${slug}`;

  return (
    <Link to={path} {...props}>
      {children}
    </Link>
  );
};

LanguageLink.propTypes = {
  route: PropTypes.string.isRequired,
  language: PropTypes.oneOf(['en', 'de']),
};

export default LanguageLink;
